var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shelf-preview"},[_c('div',{staticClass:"shelf-preview__close",on:{"click":function($event){return _vm.$emit('close-modal')}}}),_c('div',{staticClass:"shelf-preview__overlay"}),_c('div',{staticClass:"shelf-preview__inner position-r"},[(_vm.shelfList.length > 1)?_c('div',{staticClass:"shelf-preview__arrow",class:{ 'shelf-preview__arrow--disabled': _vm.currentShelf === 0 },on:{"click":function($event){return _vm.setActiveShelf(-1)}}}):_vm._e(),(_vm.shelfList.length > 1)?_c('div',{staticClass:"shelf-preview__arrow shelf-preview__arrow--right",class:{
        'shelf-preview__arrow--disabled':
          _vm.currentShelf === _vm.shelfList.length - 1,
      },on:{"click":function($event){return _vm.setActiveShelf(1)}}}):_vm._e(),_c('div',{staticClass:"shelf-preview__image-wrapper position-r",class:{ 'image-loading': _vm.loadingImage },attrs:{"id":"shelf-modal-img"}},[_c('panZoom',{attrs:{"id":"modal-panzoom-details"}},[_c('div',{staticClass:"zoomable"},[_c('img',{attrs:{"src":_vm.shelfList[_vm.currentShelf].imageURL},on:{"load":_vm.onLoad}})])]),_c('div',{staticClass:"shelf-preview__count"},[_vm._v(" "+_vm._s(_vm.currentShelf + 1)+" / "+_vm._s(_vm.shelfList.length)+" ")])],1),_c('div',{staticClass:"flex items-center shelf-preview__tools-zoom"},[_c('div',{staticClass:"flex items-center shelf-preview__tools-zoom-controls"},[_c('div',{staticClass:"\n            flex\n            items-center\n            justify-center\n            shelf-preview__tools-zoom-cta\n          ",attrs:{"disabled":_vm.imageZoomPercent === 25},on:{"click":function($event){return _vm.zoom(-1)}}},[_vm._v(" - ")]),_c('span',[_vm._v(_vm._s(_vm.imageZoomPercent)+"%")]),_c('div',{staticClass:"\n            flex\n            items-center\n            justify-center\n            shelf-preview__tools-zoom-cta shelf-preview__tools-zoom-cta--plus\n          ",attrs:{"disabled":_vm.imageZoomPercent === 100},on:{"click":function($event){return _vm.zoom(1)}}},[_vm._v(" + ")])])]),_c('div',{staticClass:"shelf-preview__info"},[_c('ul',{staticClass:"shelf-library-item__info"},[_c('li',[_c('strong',[_vm._v("Project:")]),_vm._v(" "+_vm._s(_vm.shelfList[_vm.currentShelf].Name)+" "),_c('span',{staticClass:"no-white-space"},[_vm._v("("+_vm._s(_vm.shelfList[_vm.currentShelf].numberOfProducts)+" products)")])]),_c('li',[_c('strong',[_vm._v("Project Date:")]),_vm._v(" "+_vm._s(_vm.shelfList[_vm.currentShelf].date)+" ")]),_c('li',[_c('strong',[_vm._v("Retailer:")]),_vm._v(" "+_vm._s(_vm.shelfList[_vm.currentShelf].Retailer)+" ")])])]),_c('div',{staticClass:"shelf-preview__controls flex justify-end"},[_c('div',{staticClass:"flex"},[_c('a-button',{class:{
            dlb:
              _vm.surveyType === 'packtestextend' &&
              _vm.shelfList[_vm.currentShelf].shelfID !== _vm.activeShelfId,
          },attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('select-shelf', _vm.shelfList[_vm.currentShelf])}}},[(_vm.shelfList[_vm.currentShelf].shelfID !== _vm.activeShelfId)?_c('span',[_c('font-awesome-icon',{staticClass:"mr-5",attrs:{"icon":"check"}}),_vm._v(" Select shelf")],1):_c('span',[_c('font-awesome-icon',{staticClass:"mr-5",attrs:{"icon":"list"}}),_vm._v(" Select Test Products")],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="product-list">
    <div class="page-title">
      <icon-base
        :width="32"
        :height="33"
        :viewBox1="32"
        :viewBox2="33"
        iconName="chart"
        iconColor="transparent"
        ><icon-chart-histogram
      /></icon-base>
      <div class="page-title__text">
        <h1>Stimuli</h1>
        <span>Select Test Products</span>
      </div>
    </div>
    <div class="box-wrapper">
      <StimuliTitle
        class="mb-5 mt-20"
        v-if="!brandProductsList.length"
        :title="
          !noBrandProducts
            ? 'Select a brand to see a list of products.'
            : 'There are no products for the selected brand.'
        "
      ></StimuliTitle>
      <div
        class="flex items-center justify-between pt-0 management-system__tools"
      >
        <Skeleton v-if="loadingFilters"></Skeleton>
        <div class="flex items-center management-system__filters">
          <div
            class="
              management-system__filters-item
              input-group input-group--small
            "
            :class="{ dlb: dlbStatus }"
            v-if="showBrandDropdown && !loadingBrandList"
          >
            <a-select
              class="fullWidth"
              option-filter-prop="children"
              show-search
              placeholder="Select a brand"
              :key="Math.random()"
              @change="getProducts('click', $event)"
              :default-value="defaultBrand || undefined"
            >
              <a-select-option
                v-for="item in brandList"
                :key="item.BrandName"
                :value="item.BrandName"
              >
                {{ item.BrandName }}
              </a-select-option>
            </a-select>
          </div>
          <div
            v-if="!showBrandDropdown && !loadingBrandList"
            class="product-list__selected-brand mr-10"
          >
            <strong>Selected brand:</strong> {{ currentBrand }}
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span>Change selected brand</span>
              </template>
              <font-awesome-icon
                icon="edit"
                class="cursor-pointer"
                @click="showBrandDropdown = true"
              />
            </a-tooltip>
          </div>
          <div
            class="
              management-system__filters-item
              management-system__filters-item--page
              input-group input-group--small
            "
            :class="{ dlb: dlbStatus }"
          >
            <template v-if="searchProductList.length > 10">
              <span>Page</span>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                placeholder="Please select"
                :key="Math.random()"
                @change="currentPage = $event"
                :default-value="currentPage || 1"
              >
                <a-select-option
                  v-for="page in totalPages"
                  :key="page"
                  :value="page"
                >
                  {{ page }}
                </a-select-option>
              </a-select>
              <span>of {{ totalPages }}</span>
            </template>
          </div>
          <!-- <a-button
          class="management-system__filters-search-btn"
          type="primary"
          @click="getProducts('click')"
          v-if="showBrandDropdown"
        >
          <div class="flex" v-if="loading">
            <i class="circle-loader"></i>
          </div>
          <span v-else>Find Products</span>
        </a-button> -->
        </div>
        <p
          class="management-system__tools-counter"
          v-if="brandProductsList.length"
        >
          {{ selectedRowKeys.length }} selected product<template
            v-if="selectedRowKeys.length !== 1"
            >s</template
          >
          <span
            class="management-system__tools-counter-preview"
            v-if="selectedProducts.length > 0"
            @click="productsQuickPreview = !productsQuickPreview"
            >{{ productsQuickPreviewTitle }}</span
          >
        </p>
        <div class="flex items-center management-system__filters">
          <div
            class="
              management-system__filters-item
              management-system__filters-item--search
              input-group input-group--small
            "
            :class="{
              'management-system__filters-item--search-first-field':
                !showBrandDropdown,
            }"
            v-if="
              brandProductsList.length &&
              viewType === 'list' &&
              !loadingBrandList
            "
          >
            <div class="position-r search-field">
              <icon-base
                :width="20"
                :height="20"
                :viewBox1="64"
                :viewBox2="64"
                iconName="searchThin"
                iconColor="transparent"
                iconStroke="#595959"
                class="search-icon"
                ><icon-search-thin
              /></icon-base>
              <a-input
                :class="{ active: searchParametars != '' }"
                v-model="searchParametars"
                placeholder="Search"
              >
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="close-circle"
                  v-show="searchParametars != ''"
                  @click="searchParametars = ''"
              /></a-input>
            </div>
          </div>
        </div>
        <div
          class="flex management-system__view-cta"
          v-if="brandProductsList.length"
        >
          <div
            class="flex items-center management-system__view-cta-item"
            :class="{ 'is-active': viewType === 'list' }"
            @click="viewType = 'list'"
          >
            <i class="flex items-center justify-center"
              ><font-awesome-icon icon="list"
            /></i>
            <span>List</span>
          </div>
          <div
            class="flex items-center management-system__view-cta-item"
            @click="viewType = 'shelf'"
            :class="{ 'is-active': viewType === 'shelf' }"
          >
            <i class="flex items-center justify-center"
              ><font-awesome-icon icon="store"
            /></i>
            <span>Shelf</span>
          </div>
        </div>

        <p class="management-system__shelf-info" v-if="viewType === 'shelf'">
          Please click on, then select the product(s) on shelf that you are
          testing.
        </p>
      </div>

      <div
        class="product-list__selected flex flex-wrap"
        v-if="productsQuickPreview && selectedProducts.length > 0"
      >
        <SelectedProductItem
          v-for="product in selectedProducts"
          :key="product.zoneId"
          :product="product"
          :surveyType="surveyType"
          @remove-product="selectRow(product)"
        ></SelectedProductItem>
      </div>

      <StimuliTitle
        :title="stimuliTitle"
        v-if="brandProductsList.length"
        class="mb-25"
      ></StimuliTitle>

      <div v-if="viewType === 'list'" :class="{ dlb: dlbStatus }">
        <div class="management-system__grid" v-if="brandProductsList.length">
          <ProductItem
            v-for="item in paginatedProducts"
            :key="item.areaID"
            :product="item"
            @item-click="selectRow(item)"
            :loading="loading || getCSVZoneLoader"
            :class="{ selected: isSelected(item.areaID) }"
          ></ProductItem>
        </div>
      </div>

      <a-modal
        title="Shelf view"
        :visible="viewType === 'shelf'"
        @cancel="viewType = 'list'"
        :width="1360"
        class="shelf-view-modal"
      >
        <ShelfPreview
          v-if="activeShelf"
          :shelfID="activeShelf.shelfID"
          :brandProductsList="brandProductsList"
          :key="previewShelfKey"
          :activeBrand="currentBrand"
          mode="edit"
          :selectedProducts="selectedRowKeys"
          :surveyType="surveyType"
          @selectedEditableProducts="selectedEditableProducts"
        ></ShelfPreview>
        <template #footer>
          <a-button key="back" type="primary" @click="viewType = 'list'"
            >Done</a-button
          >
        </template>
      </a-modal>
    </div>
    <div class="flex stimuli-nav">
      <div>
        <a-button @click="prevStep" v-if="!dlbStatus"
          ><icon-base
            class="mr-5 back"
            :width="20"
            :height="20"
            :viewBox1="20"
            :viewBox2="20"
            iconName="longArrowRight"
            iconStroke="#D04395"
            ><icon-long-arrow-right
          /></icon-base>
          Back</a-button
        >
      </div>
      <a-button
        type="primary"
        :disabled="selectedRowKeys.length === 0"
        @click="nextStep"
        >Next
        <icon-base
          class="ml-5"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#fff"
          ><icon-long-arrow-right /></icon-base
      ></a-button>
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import ProductItem from "@/components/packTestSurvey/ProductItem.vue";
import ShelfPreview from "@/components/general/ShelfPreview.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import SelectedProductItem from "@/components/packTestSurvey/SelectedProductItem.vue";
import Skeleton from "@/components/general/Skeleton.vue";
import _ from "lodash";
import { store } from "@/utils/store.js";
import IconBase from "@/components/general/IconBase.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import IconLongArrowRight from "@/components/icons/IconLongArrowRight.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";

const columns = [
  {
    title: "",
    scopedSlots: { customRender: "Image" },
  },
  {
    title: "Product",
    dataIndex: "productName",
  },
  {
    title: "Weight",
    dataIndex: "weight",
  },
  {
    title: "Price",
    dataIndex: "price",
  },
];

export default {
  name: "ProductList",
  components: {
    StimuliTitle,
    ShelfPreview,
    SelectedProductItem,
    Skeleton,
    ProductItem,
    IconBase,
    IconChartHistogram,
    IconLongArrowRight,
    IconSearchThin,
  },
  props: {
    activeShelf: {
      type: Object,
    },
    selectedProductsKeys: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedProducts: {
      type: Array,
      default() {
        return [];
      },
    },
    shelfImageId: {
      type: Number,
    },
    currentBrand: {
      type: String,
    },
    currentShelfId: {
      type: Number,
    },
    surveyBrand: {
      type: String,
    },
    surveyType: {
      type: String,
      default() {
        return null;
      },
    },
    studyType: {
      type: String,
    },
  },
  data() {
    return {
      brandList: [],
      brandProductsList: [],
      chosenBrand: null,
      columns,
      pagination: {},
      selectedRowKeys: [],
      viewType: "list",
      previewShelfKey: null,
      noBrandProducts: false,
      searchParametars: "",
      loading: false,
      productsQuickPreview: false,
      loadingFilters: true,
      showBrandDropdown: false,
      loadingBrandList: false,
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    paginatedProducts() {
      let start = (this.currentPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      return this.searchProductList.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.searchProductList.length / this.pageSize);
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
      };
    },
    searchProductList() {
      return this.brandProductsList.filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every((v) => item.productName.toLowerCase().includes(v));
      });
    },
    defaultBrand() {
      let brand = this.chosenBrand || this.currentBrand;
      let index = this.brandList.findIndex(
        (brand) =>
          _.startCase(brand.BrandName) === _.startCase(this.surveyBrand)
      );
      if (index !== -1) {
        brand = this.chosenBrand || this.currentBrand || this.surveyBrand;
      }
      return _.startCase(brand);
    },
    productsQuickPreviewTitle() {
      return this.productsQuickPreview ? "Hide quick preview" : "Quick preview";
    },
    getCSVZoneLoader() {
      return store.csvZoneLoader.length > 0;
    },
    dlbStatus() {
      return this.surveyType === "packtestextend";
    },
    stimuliTitle() {
      return this.studyType === "SBD"
        ? "Select a Product for the findability exercise. Click a product again to deselect."
        : "Select a Product to test. Click a product again to deselect.";
    },
  },
  watch: {
    activeShelf() {
      this.selectedRowKeys = [];
      this.getBrandList();
    },
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    getProducts(event, brand) {
      let index = this.brandList.findIndex(
        (brand) =>
          brand.BrandName.toUpperCase() === this.surveyBrand.toUpperCase()
      );
      if (
        (this.currentBrand !== undefined && this.currentBrand !== null) ||
        index !== -1
      ) {
        if (this.selectedRowKeys.length > 0) {
          let _this = this;
          this.$confirm({
            title: "Change brand",
            content: () => (
              <div>
                Are you sure you want to change a brand? If you change it, all
                products you already selected will be deleted.
              </div>
            ),
            onCancel() {
              if (brand !== undefined) {
                _this.setBrand(brand);
              }
              _this.changeBrand(event);
            },
            okText: "No",
            cancelText: "Yes, change it",
          });
        } else {
          if ((brand !== undefined && index !== -1) || event === "click") {
            this.setBrand(brand);
          }
          this.changeBrand(event);
        }
      } else if (event === "click" && brand === undefined) {
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "Please select a brand from the dropdown list then click on the 'Search Products' button.",
          placement: "bottomLeft",
          duration: 5,
        });
      } else {
        if (event === "click" || (event !== "click" && index !== -1)) {
          this.setBrand(brand);
          this.changeBrand(event);
        }
      }
    },
    changeBrand(event) {
      this.loading = true;
      let index = this.brandList.findIndex(
        (brand) =>
          _.startCase(brand.BrandName) === _.startCase(this.surveyBrand)
      );
      if (index !== -1 && this.surveyBrand !== this.currentBrand) {
        this.setBrand(this.surveyBrand);
        event = "custom";
      } else {
        this.setBrand(
          this.chosenBrand || this.currentBrand || this.surveyBrand
        );
      }

      this.setBrand(this.chosenBrand || this.currentBrand || this.surveyBrand);
      const getShelfBrandProducts = new Promise((resolve) => {
        wsUtils.GetShelfBrandProducts(
          {
            ImageId: this.activeShelf
              ? this.activeShelf.imageID
              : this.shelfImageId,
            BrandName:
              this.chosenBrand || this.currentBrand || this.surveyBrand,
            shelfId: this.activeShelf
              ? this.activeShelf.shelfID
              : this.currentShelfId,
            excludedBrand: "",
          },
          resolve
        );
      });

      getShelfBrandProducts.then((data) => {
        this.brandProductsList = data;
        const pagination = { ...this.pagination };
        pagination.total = data.length;
        pagination.hideOnSinglePage = true;
        this.pagination = pagination;
        this.loading = false;
        this.previewShelfKey = Math.random();
        data.length
          ? (this.noBrandProducts = false)
          : (this.noBrandProducts = true);

        this.setProductsDetails();
      });
      this.selectedRowKeys = [];
      if (event !== "click" && event !== "custom") {
        this.selectedRowKeys = [...this.selectedProductsKeys];
      }

      this.$emit("brandChanged", { brand: this.chosenBrand, event: event });
    },
    getBrandList() {
      this.loadingBrandList = true;
      const getShelfBrand = new Promise((resolve) => {
        wsUtils.GetShelfBrand(
          {
            ImageId: this.activeShelf
              ? this.activeShelf.imageID
              : this.shelfImageId,
          },
          resolve
        );
      });

      getShelfBrand.then((data) => {
        this.brandList = data.map((item) => {
          return {
            BrandName: item.BrandName.toUpperCase(),
          };
        });
        this.$emit("brand-list", data);
        this.getProducts();

        let index = data.findIndex(
          (brand) =>
            brand.BrandName.toUpperCase() === this.surveyBrand.toUpperCase()
        );

        index === -1
          ? (this.showBrandDropdown = true)
          : (this.showBrandDropdown = false);

        this.loadingBrandList = false;
      });
    },
    selectRow(record) {
      const selectedRowKeys = [...this.selectedRowKeys];
      if (selectedRowKeys.indexOf(record.areaID) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.areaID), 1);
        setTimeout(() => {
          this.setProductsDetails();
        }, 0);
      } else {
        if (this.selectedRowKeys.length < 10000) {
          selectedRowKeys.push(record.areaID);
          setTimeout(() => {
            this.setProductsDetails();
          }, 0);
        } else {
          this.$notification["warning"]({
            message: `Oops`,
            description: "The maximum number of selected products is 10000.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      }
      this.selectedRowKeys = selectedRowKeys;
    },
    selectedEditableProducts(e) {
      if (
        this.selectedRowKeys.length < 10000 ||
        this.selectedRowKeys.length > e.length
      ) {
        this.selectedRowKeys = e;
        setTimeout(() => {
          this.setProductsDetails();
        }, 0);
      } else {
        this.$notification["warning"]({
          message: `Oops`,
          description: "The maximum number of selected products is 10000.",
          placement: "bottomLeft",
          duration: 4,
        });
      }
    },
    setProductsDetails() {
      let products = [];
      this.selectedRowKeys.forEach((item) => {
        let product = this.brandProductsList.find(
          (product) => product.areaID === item
        );
        if (product !== undefined) {
          products.push(product);
        }
      });
      this.$emit("products-details", products);
    },
    setBrand(brand) {
      this.chosenBrand = brand;
      this.$emit("brand", brand);
    },
    setBrandDropdown(brand) {
      this.getProducts("click", brand);
    },
    nextStep() {
      this.setProductsDetails();
      this.$emit("nextStep");
    },
    prevStep() {
      this.$emit("prevStep");
    },
    isSelected(areaID) {
      return this.selectedRowKeys.some((item) => item === areaID);
    },
  },
  mounted() {},
  created() {
    let imageId = this.activeShelf
      ? this.activeShelf.imageID
      : this.shelfImageId;
    if (imageId !== null) {
      this.getBrandList();
    }

    setTimeout(() => {
      this.loadingFilters = false;
    }, 800);
  },
  activated() {},
};
</script>

<style lang="scss" scoped>
.product-list {
  .box-wrapper {
    padding-top: 8px;
  }
  &__selected {
    background: #fafafa;
    margin-bottom: 20px;
    border: 1px solid #f4f4f4;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  &__image {
    width: 70px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 80px;
    }
  }
  &__table-cta {
    .ant-btn {
      height: 33px;
    }
  }
  &__selected-brand {
    font-size: 15px;
    padding-top: 10px;
    strong {
      font-weight: 500;
    }
    svg {
      color: #646464;
      margin-left: 5px;
      transition: color 0.3s ease;
      &:hover {
        color: #4318ff;
      }
    }
  }
  ::v-deep .custom-table {
    thead {
      border-color: #fff;
      .ant-table-selection {
        display: none;
      }
    }
    tbody {
      tr {
        border-color: #fff;
        td {
          &:nth-child(2) {
            width: 100px;
          }
          &:nth-child(3) {
            min-width: 230px;
          }
          &.ant-table-selection-column {
            > span {
              pointer-events: none;
            }
          }
        }
        &.ant-table-row-selected td {
          background: #e7f4fb !important;
        }
      }
    }
    &.disable-select {
      tr:not(.ant-table-row-selected) {
        .ant-table-selection-column span {
          pointer-events: none;
        }
      }
    }
  }
  .management-system {
    &__tools {
      min-height: 84px;
      position: relative;
      margin-bottom: 3px;
      padding-bottom: 0;
      gap: 20px;
      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
      }
    }
    &__shelf-info {
      position: absolute;
      bottom: -81px;
      left: 0;
      font-size: 12px;
      margin-bottom: 0;
    }
    &__filters-item {
      > * {
        color: #4c4c4c;
        font-weight: 500;
      }
      ::v-deep .ant-select {
        .ant-select-selection {
          &--single {
            border-color: #e0e0e0;
          }
          &__placeholder {
            color: #b0b0b0;
          }
        }
      }
      ::v-deep .ant-input {
        border-color: #b0b0b0;
      }
      &--brand {
        display: none;
      }
      &--brand-show {
        display: block;
      }
      &--search {
        position: relative;
        ::v-deep .ant-input {
          border-color: #f4f4f4;
          background: #f4f4f4;
        }
      }
      &--search-first-field {
        // margin-left: 0;
        &:after {
          top: 27px;
        }
        // @media screen and (max-width: 800px) {
        //   width: 100%;
        //   max-width: 100%;
        // }
      }
    }
    &__view-cta {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    &__tools-counter {
      @media screen and (max-width: 800px) {
        padding-top: 13px;
        &:after {
          display: none;
        }
      }
    }
  }
}

.shelf-view-modal {
  ::v-deep .ant-modal-body {
    padding: 0;
    overflow: hidden;
  }
}
</style>

<template>
  <div>
    <div class="page-title">
      <icon-base
        :width="32"
        :height="33"
        :viewBox1="32"
        :viewBox2="33"
        iconName="chart"
        iconColor="transparent"
        ><icon-chart-histogram
      /></icon-base>
      <div class="page-title__text">
        <h1>Stimuli</h1>
        <span>Select Shelf</span>
      </div>
    </div>
    <div class="shelf-list box-wrapper">
      <Loader
        text="Loading Shelf list..."
        v-if="loading"
        :paddingTop="150"
        :paddingBottom="150"
      ></Loader>
      <div v-else>
        <div v-if="shelfList.length > 0">
          <StimuliTitle :title="stimuliTitle" :maxWidth="900"></StimuliTitle>

          <div class="shelf-list__title">
            <h2>Please select one to continue.</h2>
            <p>{{ activeShelfId !== null ? "1" : "0" }} selected products</p>
          </div>

          <div class="ant-row flex flex-wrap">
            <div
              class="ant-col"
              v-for="(item, index) in shelfList"
              :key="item.shelfID"
            >
              <div
                class="shelf-library-item position-r"
                :class="{
                  'shelf-library-item--is-active':
                    item.shelfID === activeShelfId,
                }"
              >
                <div class="shelf-library-item__image image-loading">
                  <div class="shelf-library-item__cta">
                    <a-button
                      type="primary"
                      @click="setActiveShelf(item)"
                      :class="{
                        dlb: dlbStatus && item.shelfID !== activeShelfId,
                      }"
                    >
                      <span v-if="item.shelfID !== activeShelfId"
                        ><font-awesome-icon icon="check" /> Select shelf</span
                      >
                      <span v-else
                        ><font-awesome-icon icon="list" /> Select Test
                        Products</span
                      >
                    </a-button>
                    <a-button
                      type="text"
                      @click="
                        (shelfPreview = true), (currentShelfPreview = index)
                      "
                    >
                      <font-awesome-icon icon="eye" class="mr-5" /> Shelf
                      preview
                    </a-button>
                  </div>
                  <img
                    :src="item.thumbImageURL"
                    @error="
                      ($event.target.src = item.imageURL),
                        createImageThumb(item)
                    "
                    @load="
                      $event.target.parentNode.classList.toggle(
                        'image-loading--loaded'
                      )
                    "
                    alt="Shelf"
                  />
                </div>
                <h2>Choice {{ index + 1 }}</h2>
                <ul class="shelf-library-item__info">
                  <li>
                    <icon-base
                      :width="24"
                      :height="24"
                      :viewBox1="24"
                      :viewBox2="24"
                      iconName="box"
                      iconColor="#fff"
                      ><icon-box
                    /></icon-base>
                    {{ item.Name }}
                  </li>
                  <li>
                    <icon-base
                      :width="24"
                      :height="24"
                      :viewBox1="24"
                      :viewBox2="24"
                      iconName="cart"
                      iconColor="#fff"
                      ><icon-cart
                    /></icon-base>
                    {{ item.numberOfProducts }} Products
                  </li>
                  <li>
                    <icon-base
                      :width="24"
                      :height="24"
                      :viewBox1="24"
                      :viewBox2="24"
                      iconName="calendar"
                      iconColor="#fff"
                      ><icon-calendar
                    /></icon-base>
                    {{ item.date }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="shelf-list__title">
            <h2>There are no shelves available for the selected criteria.</h2>
          </div>
        </div>

        <div class="shelf-list__help-cta flex items-center">
          <p>Not finding what you're looking for?</p>
          <div class="flex">
            <button
              class="btn-reset cursor-pointer flex items-center"
              @click="shelfHelpModal = true"
            >
              Contact us
            </button>
            <span v-if="!isPackSbdTestPresent">or</span>
            <button
              v-if="!isPackSbdTestPresent"
              class="btn-reset cursor-pointer flex items-center"
              @click="redirectToCustomPack"
            >
              Upload shelf planogram (.psa file)
            </button>
          </div>
        </div>
      </div>

      <ShelfPreviewModal
        v-if="shelfPreview"
        :shelfList="shelfList"
        :currentShelf="currentShelfPreview"
        :activeShelfId="activeShelfId"
        @set-active-shelf="currentShelfPreview = $event"
        @close-modal="shelfPreview = false"
        @select-shelf="setActiveShelf($event)"
        :surveyType="surveyType"
      ></ShelfPreviewModal>
      <a-modal
        title="Not finding what you're looking for?"
        :visible="shelfHelpModal"
        @cancel="shelfHelpModal = false"
        :afterClose="closeShelfHelpModal"
        :footer="null"
        :width="382"
      >
        <p class="mb-20">
          Not finding the shelf you're looking for or want to reach out to
          Hotspex to build a new one? <br />Please enter some details below
          about the category, country and retailer and someone will get back to
          you shortly!
        </p>
        <a-row :gutter="16">
          <a-col :span="24" class="large-field">
            <a-textarea
              :auto-size="{ minRows: 6 }"
              v-model.trim="helpMessage"
            />
          </a-col>
        </a-row>

        <div class="shelf-list__chat flex items-center flex-col">
          <p><span>OR</span></p>
          <div @click.stop="setActiveChatBtn">
            <a
              class="btn-teams-chat flex items-center justify-center"
              href="#"
              onclick="SI_API.showPopup();"
              ><i></i>Live Chat</a
            >
          </div>
        </div>

        <a-row :gutter="16" class="mt-10">
          <a-col :span="24" class="flex justify-end">
            <a-button
              class="flex items-center mr-10"
              @click="shelfHelpModal = false"
            >
              <span>Cancel</span>
            </a-button>
            <a-button
              type="primary"
              @click="sendHelpRequest"
              :disabled="helpMessage === ''"
            >
              Submit
            </a-button></a-col
          >
        </a-row>
      </a-modal>
    </div>

    <div class="flex stimuli-nav">
      <a-button @click="prevStep"
        ><icon-base
          class="mr-5 back"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#D04395"
          ><icon-long-arrow-right
        /></icon-base>
        Back
      </a-button>
      <a-button
        type="primary"
        :disabled="activeShelfId === null"
        @click="$emit('nextStep')"
        >Next
        <icon-base
          class="ml-5"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#fff"
          ><icon-long-arrow-right /></icon-base
      ></a-button>
    </div>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import ShelfPreviewModal from "@/components/packTestSurvey/ShelfPreviewModal.vue";
import Loader from "@/components/general/Loader.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import IconCart from "@/components/icons/IconCart.vue";
import IconBox from "@/components/icons/IconBox.vue";
import IconLongArrowRight from "@/components/icons/IconLongArrowRight.vue";

export default {
  name: "PackTestShelfList",
  components: {
    Loader,
    StimuliTitle,
    ShelfPreviewModal,
    IconBase,
    IconChartHistogram,
    IconCalendar,
    IconCart,
    IconBox,
    IconLongArrowRight,
  },
  props: {
    shelfList: Array,
    loading: {
      type: Boolean,
      default() {
        return true;
      },
    },
    category: {
      type: String,
      default() {
        return "";
      },
    },
    country: {
      type: String,
      default() {
        return "";
      },
    },
    activeShelfId: {
      type: Number,
      default() {
        return null;
      },
    },
    surveyType: {
      type: String,
      default() {
        return null;
      },
    },
    surveyID: {
      type: String,
    },
    isPackSbdTestPresent: {
      type: Boolean,
    },
  },
  data() {
    return {
      shelfHelpModal: false,
      formValidations: [],
      helpMessage: "",
      shelfPreview: false,
      currentShelfPreview: 0,
      ssoLogin: null,
    };
  },
  computed: {
    stimuliTitle() {
      return `The following shelves are available in the <strong>${this.category}</strong> category for <strong>${this.country}</strong>. Please select one to continue.`;
    },
    dlbStatus() {
      return this.surveyType === "packtestextend";
    },
  },
  watch: {},
  methods: {
    prevStep() {
      if (
        this.ssoLogin !== null &&
        this.ssoLogin.action !== "" &&
        this.ssoLogin.pgprojectid !== ""
      ) {
        window.open(
          `https://d11qiq41szmpd8.cloudfront.net/${this.ssoLogin.pgprojectid}`,
          "_self",
          "noreferrer"
        );
      } else {
        this.$router.push({
          name: this.isPackSbdTestPresent
            ? "pack-sbd-test-setting"
            : "pack-test-setting",
          params: { id: this.surveyID },
        });
      }
    },
    setActiveChatBtn() {
      document.getElementById("silc-btn").classList.add("active");
      this.removeActiveChatBtn();
    },
    removeActiveChatBtn() {
      document
        .getElementById("silc-btn")
        .addEventListener("click", function () {
          document.getElementById("silc-btn").classList.remove("active");
        });
    },
    setActiveShelf(data) {
      let currentPackTestPriceData = jsUtils.getPackTestPrice();
      jsUtils.setPackTestPrice({
        ...currentPackTestPriceData,
        isCustom: false,
      });
      this.shelfPreview = false;
      this.$emit("ShelfData", data);
    },
    createImageThumb(item) {
      const getThumbnailImage = new Promise((resolve) => {
        wsUtils.GetThumbnailImage(
          {
            CipId: item.cipId,
          },
          resolve
        );
      });
      getThumbnailImage.then(() => {});
    },
    closeShelfHelpModal() {
      this.helpMessage = "";
    },
    sendHelpRequest() {
      this.$emit("send-help-request", this.helpMessage);
      this.shelfHelpModal = false;
    },
    redirectToCustomPack() {
      let currentPackTestPriceData = jsUtils.getPackTestPrice();
      jsUtils.setPackTestPrice({
        ...currentPackTestPriceData,
        isCustom: true,
      });

      this.$router.push({
        name: "stimuli-custom",
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
  mounted() {},
  created() {},
  activated() {
    let ssoData = jsUtils.readCookie("sso-login");
    let SAMLSSOUser = jsUtils.readCookie("SAMLSSOSignedIn");
    if (ssoData !== "" && SAMLSSOUser) {
      this.ssoLogin = JSON.parse(ssoData);
    }
  },
};
</script>

<style lang="scss" scoped>
.shelf-list {
  &__title {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 6px;
    padding-top: 3px;
    h2 {
      color: var(--blueNavy);
      font-size: 20px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.4px;
    }
    p {
      color: var(--blueNavy);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
    }
  }
  &__help-cta {
    gap: 6px;
    @media screen and (max-width: 800px) {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 0;
      font-weight: 500;
      color: #a3aed0;
    }
    button {
      font-weight: 500;
      text-decoration: underline;
      color: var(--blueNavy);
      cursor: pointer;
      i {
        margin: 0 5px 0 0;
      }
    }
    span {
      padding: 0 5px;
      color: #a3aed0;
    }
    &--title {
      p {
        font-size: 18px;
        font-weight: 400;
        max-width: 900px;
        line-height: 28px;
        margin-bottom: 0;
        padding-right: 5px;
        @media screen and (max-width: 800px) {
          font-size: 14px;
        }
      }
      button,
      span {
        font-size: 18px;
      }
      span {
        padding: 0 5px;
      }
    }
  }
  &__chat {
    margin-bottom: 21px;
    padding-bottom: 34px;
    border-bottom: 1px solid #f0f0f0;
    p {
      position: relative;
      text-align: center;
      width: 100%;
      &::before {
        position: absolute;
        left: 0;
        width: 100%;
        top: 10px;
        content: "";
        border-bottom: 1px solid #f0f0f0;
      }
      span {
        background: #fff;
        z-index: 1;
        position: relative;
        padding: 0 7px;
      }
    }
  }
  .ant-row {
    margin: 0 -12px;
  }
  .ant-col {
    padding: 0 12px;
    width: 33.33%;
    margin-bottom: 24px;
    @media screen and (max-width: 1400px) {
      width: 50%;
    }
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}

.shelf-library-item {
  &__cta {
    .dlb {
      opacity: 0.7 !important;
    }
  }
}
</style>

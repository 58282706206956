<template>
  <div class="competitive-benchmark">
    <div class="page-title">
      <icon-base
        :width="32"
        :height="33"
        :viewBox1="32"
        :viewBox2="33"
        iconName="chart"
        iconColor="transparent"
        ><icon-chart-histogram
      /></icon-base>
      <div class="page-title__text">
        <h1>Stimuli</h1>
        <span>Define Superiority Benchmark</span>
      </div>
    </div>
    <div class="box-wrapper">
      <StimuliTitle :title="stimuliTitle"></StimuliTitle>

      <div
        v-for="item in chosenProducts"
        :key="item.areaID"
        class="flex justify-between competitive-benchmark__row"
      >
        <div
          class="competitive-benchmark__item"
          :class="{ 'competitive-benchmark__item--deep-dive': item.deepDive }"
        >
          <div class="competitive-benchmark__item-inner">
            <div class="competitive-benchmark__item-info-wrapper">
              <div
                class="competitive-benchmark__item-flag"
                v-if="item.deepDive"
              >
                Priority Product
              </div>
              <div class="competitive-benchmark__item-image image-loading">
                <img
                  :src="item.ImageURL"
                  @load="
                    $event.target.parentNode.classList.toggle(
                      'image-loading--loaded'
                    )
                  "
                />
              </div>
              <div class="competitive-benchmark__item-info">
                <h3>{{ item.productName }}</h3>
                <span
                  >Weight: <strong>{{ item.weight }}</strong></span
                >
                <span
                  >Price: <strong>{{ item.price }}</strong></span
                >
              </div>
            </div>
            <div class="competitive-benchmark__item-select">
              <div>
                <a-button
                  type="primary"
                  @click="openCompetitorModal(item)"
                  v-if="!dlbStatus"
                >
                  <span
                    v-if="
                      chosenProductsList.hasOwnProperty('product' + item.zoneId)
                    "
                    >Change competitor pack</span
                  >

                  <span v-else>Select competitor pack</span>
                </a-button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="
            competitive-benchmark__item
            competitive-benchmark__item--competitor-pack
          "
          :class="{
            'competitive-benchmark__item--dark':
              chosenProductsList.hasOwnProperty('product' + item.zoneId),
          }"
        >
          <div class="competitive-benchmark__item-inner">
            <div
              class="competitive-benchmark__item-info-wrapper"
              v-if="chosenProductsList.hasOwnProperty('product' + item.zoneId)"
            >
              <div class="competitive-benchmark__item-image">
                <img
                  :src="
                    chosenProductsList.hasOwnProperty('product' + item.zoneId)
                      ? chosenProductsList['product' + item.zoneId].ImageURL
                      : ''
                  "
                />
              </div>

              <div class="competitive-benchmark__item-info">
                <h3>
                  {{
                    chosenProductsList.hasOwnProperty("product" + item.zoneId)
                      ? chosenProductsList["product" + item.zoneId]
                          .productName ||
                        chosenProductsList["product" + item.zoneId].ProductName
                      : ""
                  }}
                </h3>
                <span
                  >Weight:
                  <strong>
                    {{
                      chosenProductsList.hasOwnProperty("product" + item.zoneId)
                        ? chosenProductsList["product" + item.zoneId].weight ||
                          chosenProductsList["product" + item.zoneId].Weight
                        : ""
                    }}</strong
                  ></span
                >
                <span
                  >Price:
                  <strong>{{
                    chosenProductsList.hasOwnProperty("product" + item.zoneId)
                      ? chosenProductsList["product" + item.zoneId].price ||
                        chosenProductsList["product" + item.zoneId].Price
                      : ""
                  }}</strong></span
                >
              </div>
            </div>

            <div
              class="general-checkbox flex items-center"
              v-if="chosenProductsList.hasOwnProperty('product' + item.zoneId)"
            >
              <input
                type="checkbox"
                :id="item.zoneId"
                @change="
                  $emit('competitive-products', chosenProductsList),
                    $emit('enable-deep-dive')
                "
                v-model="
                  chosenProductsList['product' + item.zoneId]
                    .CompetitiveDeepDive
                "
                :class="{ dlb: dlbStatus }"
              />
              <label :for="item.zoneId" :class="{ dlb: dlbStatus }"
                ><span v-if="studyType !== 'SBD'"
                  >Enable Additional Pack Diagnostics</span
                ><span v-else>Enable Findability Exercise</span>
              </label>
              <div class="ml-10 flex">
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--step-nav"
                >
                  <template slot="title">
                    <span
                      >Adding pack diagnostics for this package this will
                      increase the overall sample size for your study adding
                      incremental costs.
                    </span>
                  </template>

                  <a-icon type="info-circle" />
                </a-tooltip>
              </div>
            </div>
            <div
              class="competitive-benchmark__item-delete"
              v-if="
                chosenProductsList.hasOwnProperty('product' + item.zoneId) &&
                !dlbStatus
              "
              @click="deleteChosenProduct(item.zoneId)"
            >
              <icon-base
                @mouseover="activeColumn = itemIndex"
                @mouseout="activeColumn = null"
                :width="48"
                :height="48"
                :viewBox1="48"
                :viewBox2="48"
                iconName="delete"
                ><icon-delete
              /></icon-base>
            </div>

            <p
              v-if="!chosenProductsList.hasOwnProperty('product' + item.zoneId)"
            >
              No competitor pack selected.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between stimuli-nav">
      <a-button @click="$emit('prevStep')"
        ><icon-base
          class="ml-5 back"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#D04395"
          ><icon-long-arrow-right
        /></icon-base>
        Back
      </a-button>
      <a-button
        type="primary"
        :disabled="nextDisable"
        @click="$emit('nextStep')"
      >
        Next
        <icon-base
          class="ml-5"
          :width="20"
          :height="20"
          :viewBox1="20"
          :viewBox2="20"
          iconName="longArrowRight"
          iconStroke="#fff"
          ><icon-long-arrow-right
        /></icon-base>
      </a-button>
    </div>
    <a-modal
      :visible="competitorModal"
      @cancel="closeCompetitorModal"
      centered
      wrapClassName="ant-modal--competitive-benchmark"
      title="Competitive products"
      :width="950"
    >
      <div class="section-info mb-35">
        <p>
          Please select the relevant competitive product for
          {{ activeProductName }}.
        </p>
      </div>
      <div class="management-system__tools">
        <div class="flex items-end management-system__filters">
          <div
            class="
              input-group input-group--small
              management-system__filters-item
            "
          >
            <a-select
              class="fullWidth"
              option-filter-prop="children"
              show-search
              placeholder="Please select brand"
              :key="Math.random()"
              @change="setBrand"
              :default-value="
                chosenBrand['table' + activeTableId] || 'All brands'
              "
            >
              <a-select-option :key="Math.random()" value="All brands"
                >All brands</a-select-option
              >
              <a-select-option
                v-for="item in brandList"
                :key="item.BrandName"
                :value="item.BrandName"
                :disabled="
                  productsDetailsInfo.Brand !== null
                    ? item.BrandName.toLowerCase() ===
                      productsDetailsInfo.Brand.toLowerCase()
                    : false
                "
              >
                {{ item.BrandName }}
              </a-select-option>
            </a-select>
          </div>
          <div
            class="
              input-group input-group--small
              management-system__filters-item
            "
            v-if="
              brandProducts.hasOwnProperty('table' + activeTableId)
                ? brandProducts['table' + activeTableId].length > 0
                  ? true
                  : false
                : false
            "
          >
            <div class="position-r search-field">
              <icon-base
                class="search-icon"
                :width="20"
                :height="20"
                :viewBox1="64"
                :viewBox2="64"
                iconName="searchThin"
                iconColor="transparent"
                iconStroke="#000"
                ><icon-search-thin
              /></icon-base>
              <a-input
                :class="{ active: searchParametars != '' }"
                v-model="searchParametars"
                placeholder="Insert product name"
              >
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="close-circle"
                  v-show="searchParametars != ''"
                  @click="searchParametars = ''"
              /></a-input>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="{ 'management-system__table--disable': setAllProductsInfo }"
        v-for="item in chosenProducts"
        :key="item.zoneId"
      >
        <div
          v-if="
            item.zoneId === activeTableId &&
            brandProducts['table' + activeTableId].length
          "
          class="management-system__grid"
        >
          <ProductItem
            v-for="item in paginatedProducts"
            :key="item.areaID"
            :product="item"
            @item-click="selectRow(item)"
            :class="{
              selected: isSelected(item.areaID),
            }"
            :disabled="selectedRowKeys['table' + activeTableId].length === 1"
          ></ProductItem>
        </div>
        <DataInfo
          v-if="noBrandProducts"
          :border0="true"
          :paddingTop="100"
          :paddingBottom="100"
          text="
           There are no products for the selected brand...
          "
        ></DataInfo>
        <Loader
          text="Loading products..."
          v-if="loading && item.zoneId === activeTableId"
          :paddingTop="100"
          :paddingBottom="100"
        ></Loader>
      </div>

      <div
        class="flex justify-end mt-25"
        v-if="!loading && searchProductList.length > pageSize"
      >
        <a-pagination
          :current="currentPage"
          :pageSize="pageSize"
          show-less-items
          :total="brandProducts['table' + activeTableId].length"
          @change="currentPage = $event"
        />
      </div>

      <div
        class="competitive-benchmark__copy-product"
        v-if="setAllProductsInfo"
      >
        <a-icon type="question-circle" />
        <span>Use this competitive benchmark for all test products</span>
        <div>
          <button @click="setAllProducts" class="btn-reset">Yes</button> /
          <button class="btn-reset" @click="closeCompetitorModal">No</button>
        </div>
      </div>
      <template #footer>
        <a-button key="back" type="primary" @click="closeCompetitorModal"
          >Done</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import StimuliTitle from "@/components/packTestSurvey/StimuliTitle.vue";
import * as wsUtils from "@/utils/wsUtils.js";
import DataInfo from "@/components/general/DataInfo.vue";
import _ from "lodash";
import Loader from "@/components/general/Loader.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import IconDelete from "@/components/icons/IconDelete.vue";
import IconLongArrowRight from "@/components/icons/IconLongArrowRight.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import ProductItem from "@/components/packTestSurvey/ProductItem.vue";

const columns = [
  {
    title: "",
    scopedSlots: { customRender: "Image" },
  },
  {
    title: "Product",
    dataIndex: "productName",
  },
  {
    title: "Weight",
    dataIndex: "weight",
  },
  {
    title: "Price",
    dataIndex: "price",
  },
];

export default {
  name: "StimuliCompetitiveBenchmark",
  components: {
    StimuliTitle,
    DataInfo,
    Loader,
    IconBase,
    IconChartHistogram,
    IconDelete,
    IconLongArrowRight,
    IconSearchThin,
    ProductItem,
  },
  props: {
    deepDiveProducts: {
      type: Array,
    },
    chosenProducts: {
      type: Array,
    },
    brandList: {
      type: Array,
    },
    shelfImageId: {
      type: Number,
    },
    shelfId: {
      type: Number,
    },
    activeBrand: {
      type: String,
    },
    activeStep: {
      type: Number,
    },
    productsDetailsInfo: {
      type: Object,
    },
    editableSurvey: {
      type: Boolean,
    },
    nextDisable: {
      type: Boolean,
    },
    activeDeepDiveStep: {
      type: Boolean,
      default() {
        return false;
      },
    },
    surveyType: {
      type: String,
      default() {
        return null;
      },
    },
    studyType: {
      type: String,
    },
  },
  data() {
    return {
      competitorModal: false,
      chosenBrand: {},
      loading: false,
      brandProducts: {},
      pagination: {},
      noBrandProducts: false,
      searchParametars: "",
      selectedRowKeys: {},
      chosenProductsList: {},
      columns,
      activeTableId: null,
      activeTableSelectedRows: [],
      refreshKey: 0,
      disableNextStep: false,
      editModeSet: false,
      tempChosenProduct: null,
      setAllProductsInfo: false,
      activeProductName: null,
      currentPage: 1,
      pageSize: 10,
    };
  },
  watch: {
    searchParametars() {
      this.pagination.total = this.searchProductList.length;
    },
    activeStep(newVal) {
      if (newVal === 5) {
        this.setData();
        if (this.editableSurvey && !this.editModeSet) {
          this.setEditMode();
        }
        this.$emit("competitive-products", this.chosenProductsList);
        this.nextStep();
      }
    },
  },
  computed: {
    searchProductList() {
      if (this.activeTableId === null) {
        return [];
      }

      this.refreshKey;
      return this.brandProducts["table" + this.activeTableId].filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every((v) => item.productName.toLowerCase().includes(v));
      });
    },

    rowSelection() {
      return {
        selectedRowKeys: this.activeTableSelectedRows,
      };
    },
    dlbStatus() {
      return this.surveyType === "packtestextend";
    },

    paginatedProducts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.searchProductList.slice(start, end);
    },
    stimuliTitle() {
      return this.studyType === "SBD"
        ? `Please select the relevant competitive product or products. If you want findability on the competitive product, select "Enable Findability Exercise".`
        : "Please select the relevant competitive product for each test package.";
    },
  },
  methods: {
    isSelected(areaID) {
      return this.selectedRowKeys["table" + this.activeTableId].some(
        (item) => item === areaID
      );
    },
    openCompetitorModal(item) {
      this.activeProductName = item.productName;
      this.competitorModal = true;
      this.activeTableId = item.zoneId;
      if (this.brandProducts["table" + this.activeTableId].length === 0) {
        this.getProducts("all");
      }
      this.activeTableSelectedRows = [
        ...this.selectedRowKeys["table" + this.activeTableId],
      ];
    },
    closeCompetitorModal() {
      this.activeProductName = null;
      this.competitorModal = false;
      this.activeTableSelectedRows = [];
      this.searchParametars = "";
      this.setAllProductsInfo = false;
    },
    deleteChosenProduct(productId) {
      let _this = this;
      this.$confirm({
        title: "Delete product",
        content: () => (
          <div>Are you sure you want to delete a chosen product?</div>
        ),
        onCancel() {
          const productList = { ..._this.chosenProductsList };
          delete productList["product" + productId];
          _this.chosenProductsList = productList;
          _this.selectedRowKeys["table" + productId] = [];
          _this.nextStep();
          _this.$emit("competitive-products", _this.chosenProductsList);
        },
        okText: "No",
        cancelText: "Yes",
      });
    },
    setBrand(brand) {
      this.chosenBrand["table" + this.activeTableId] = brand;
      if (brand === "All brands") {
        this.getProducts("all");
      } else {
        this.getProducts();
      }
    },
    getProducts(type) {
      if (
        this.chosenBrand["table" + this.activeTableId] !== undefined ||
        type === "all"
      ) {
        this.loading = true;
        this.brandProducts["table" + this.activeTableId] = [];
        const getShelfBrandProducts = new Promise((resolve) => {
          wsUtils.GetShelfBrandProducts(
            {
              ImageId: this.shelfImageId,
              BrandName:
                type === "all"
                  ? ""
                  : this.chosenBrand["table" + this.activeTableId],
              shelfId: this.shelfId,
              excludedBrand: this.activeBrand,
            },
            resolve
          );
        });

        getShelfBrandProducts.then((data) => {
          this.brandProducts["table" + this.activeTableId] = data;
          const pagination = { ...this.pagination };
          pagination.total = data.length;
          pagination.hideOnSinglePage = true;
          pagination.defaultPageSize = 25;
          this.pagination = pagination;
          this.loading = false;
          data.length
            ? (this.noBrandProducts = false)
            : (this.noBrandProducts = true);
          this.refreshKey = Math.random();
          this.currentPage = 1;
        });
        this.selectedRowKeys["table" + this.activeTableId] = [];
      } else {
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "Please select a brand from the dropdown list then click on the 'Search Products' button.",
          placement: "bottomLeft",
          duration: 5,
        });
      }
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.selectRow(record);
          },
        },
      };
    },
    selectRow(record) {
      let currentRowKeys = this.selectedRowKeys["table" + this.activeTableId];
      const selectedRowKeys = [...currentRowKeys];
      if (selectedRowKeys.indexOf(record.areaID) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.areaID), 1);
        setTimeout(() => {
          this.setProductsDetails();
        }, 0);
      } else {
        if (this.selectedRowKeys["table" + this.activeTableId].length < 1) {
          selectedRowKeys.push(record.areaID);
          setTimeout(() => {
            this.setProductsDetails();
          }, 0);
        } else {
          this.$notification["warning"]({
            message: `Oops`,
            description: "The maximum number of selected products is 1.",
            placement: "bottomLeft",
            duration: 4,
          });
        }
      }
      this.selectedRowKeys["table" + this.activeTableId] = selectedRowKeys;
      this.activeTableSelectedRows = selectedRowKeys;
    },
    setProductsDetails() {
      let product;
      this.selectedRowKeys["table" + this.activeTableId].forEach((item) => {
        product = this.brandProducts["table" + this.activeTableId].find(
          (product) => product.areaID === item
        );

        if (product !== undefined) {
          product.parentZoneId = this.activeTableId;
          this.chosenProductsList["product" + this.activeTableId] = product;
          this.tempChosenProduct = product;
        }
      });
      if (product === undefined) {
        const productList = { ...this.chosenProductsList };
        delete productList["product" + this.activeTableId];
        this.chosenProductsList = productList;
      }
      this.setAllProductsInfo =
        Object.keys(this.chosenProductsList).length !==
          this.chosenProducts.length &&
        this.chosenProducts.length > 1 &&
        Object.keys(this.chosenProductsList).length === 1;
      this.nextStep();
      this.$emit("competitive-products", this.chosenProductsList);
    },
    setAllProducts() {
      this.chosenProducts.forEach((item) => {
        this.chosenProductsList["product" + item.zoneId] =
          this.tempChosenProduct;
        this.chosenProductsList["product" + item.zoneId] = Object.assign(
          {},
          this.tempChosenProduct,
          {
            parentZoneId: item.zoneId,
          }
        );
      });

      this.$forceUpdate();
      this.$emit("competitive-products", this.chosenProductsList);
      this.closeCompetitorModal();
      this.nextStep();
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    setData() {
      let productsZoneID = [];
      let tableZoneID = [];
      this.chosenProducts.forEach((item) => {
        if (this.selectedRowKeys["table" + item.zoneId] === undefined) {
          this.selectedRowKeys["table" + item.zoneId] = [];
        }
        if (this.brandProducts["table" + item.zoneId] === undefined) {
          this.brandProducts["table" + item.zoneId] = [];
        }
        productsZoneID.push("product" + item.zoneId);
        tableZoneID.push("table" + item.zoneId);
      });

      this.brandProducts = _.pick(this.brandProducts, tableZoneID);
      this.chosenBrand = _.pick(this.chosenBrand, tableZoneID);
      this.chosenProductsList = _.pick(this.chosenProductsList, productsZoneID);
      this.selectedRowKeys = _.pick(this.selectedRowKeys, tableZoneID);
    },
    nextStep() {
      if (
        Object.keys(this.chosenProductsList).length ===
          this.chosenProducts.length &&
        this.chosenProducts.length !== 0
      ) {
        this.disableNextStep = false;
      } else {
        this.disableNextStep = true;
      }
      this.$emit("disable-next-step", this.disableNextStep);
    },
    setEditMode() {
      this.productsDetailsInfo.zones.forEach((item) => {
        if (
          item.CompetitiveProduct !== undefined &&
          item.CompetitiveProduct.ZoneID !== 0
        ) {
          this.chosenProductsList["product" + item.ZoneID] =
            item.CompetitiveProduct;
          this.chosenProductsList["product" + item.ZoneID].CompetitiveDeepDive =
            item.CompetitiveDeepDive;
          this.chosenProductsList["product" + item.ZoneID].parentZoneId =
            item.ZoneID;
        } else {
          delete this.chosenProductsList["product" + item.ZoneID];
        }
      });
      this.editModeSet = true;
    },
  },
  created() {
    if (this.activeStep === 5) {
      this.setData();
      if (this.editableSurvey && !this.editModeSet) {
        this.setEditMode();
      }
      this.$emit("competitive-products", this.chosenProductsList);
    }
    this.nextStep();
  },
};
</script>

<style lang="scss" scoped>
.competitive-benchmark {
  padding-bottom: 65px;
  &__row {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item {
    width: 100%;
    max-width: 310px;
    background: #fff;
    z-index: 2;
    &--competitor-pack {
      z-index: 1;
      position: relative;
      min-height: 107px;
      max-width: 392px;
      .competitive-benchmark__item-inner {
        overflow: visible;
      }
      .competitive-benchmark__item-info-wrapper {
        box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.17);
      }
      &:after {
        width: 100%;
        height: 2px;
        position: absolute;
        right: 100%;
        bottom: 50%;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23fab3c7' stroke-width='4' stroke-dasharray='11%2c 28' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
    }
    &--deep-dive {
      .competitive-benchmark__item-info-wrapper {
        border-color: var(--red);
        box-shadow: 0 0 0 3px var(--red);
        margin-bottom: 48px;
      }
    }
  }

  &__item-delete {
    font-size: 19px;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--red);
    transition: background 0.25s ease;
    z-index: 1;
  }
  &__item-inner {
    border-radius: 20px;
    // border: 2px dashed rgba(255, 0, 67, 0.3);
    padding: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23fab3c7' stroke-width='4' stroke-dasharray='16%2c 28' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    p {
      font-size: 14px;
      color: #464444;
      text-align: center;
      width: 100%;
      margin: auto;
    }
    .general-checkbox {
      margin: 0;
      background: #e0e5f2;
      width: 100%;
      padding: 9px 19px;
      border-radius: 5px;
      label {
        display: inline-flex;
        padding: 3px 4px 3px 28px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
        &:after {
          left: 0px;
          top: 6px;
          border: 2px solid #333333;
          width: 18px;
          height: 18px;
        }
        &:before {
          left: 4px;
          top: 10px;
        }
      }
      input:checked + label:after {
        background: #333333 !important;
      }

      .anticon-info-circle {
        font-size: 21px;
      }
    }
    @media screen and (max-width: 980px) {
      flex-wrap: wrap;
    }
  }
  &__item-flag {
    padding: 5px 14px;
    position: absolute;
    border-radius: 5px;
    top: 100%;
    left: 50%;
    background: var(--red);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  &__item-image {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 96px;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      height: 90%;
      object-fit: contain;
    }
  }
  &__item-info-wrapper {
    padding: 12px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 14px;
    position: relative;
    flex: 1;
  }
  &__item-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    width: 100%;
    padding-bottom: 15px;
    h3 {
      color: var(--dark);
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      flex: 1;
      margin-bottom: 15px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.32px;
      strong {
        font-weight: 500;
      }
    }
  }
  &__item-select {
    padding: 0 10px;
    width: 100%;
    .ant-btn {
      min-width: 185px;
      border-radius: 5px;
      background: #a3aed0;
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }
  }
  &__image {
    width: 70px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 60px;
    }
  }
  &__copy-product {
    position: absolute;
    padding: 15px 28px;
    border-radius: 8px;
    box-shadow: 0 0 26px 4px rgba(0, 0, 0, 0.09);
    background: #fff;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: block;
      margin-bottom: 4px;
    }
    button {
      margin: 0 4px;
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline;
    }
    .anticon {
      font-size: 24px;
      margin-bottom: 8px;
      color: #3c90e6;
    }
  }
}

::v-deep {
  .ant-modal {
    &--competitive-benchmark {
      margin-top: 20px;
      .management-system {
        &__tools {
          padding: 0 0 15px;
        }
      }
    }
  }
}
</style>
